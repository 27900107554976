.Popup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    color: white;
    font-size: 20px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.7);
}

.popupContent {
    width: 652px;
    height: 398px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.popupContentL {
    z-index: 5;
}

.popupContentRTop {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.popupContentTitle {
    font-size: 36px;
    font-weight: 600;
}

.popupContentR {
    background-color: #1997d6;
    width: 100%;
    height: 100%;
    margin-left: -82px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 44px;
    align-items: center;
    box-shadow: 0px 4px 10px 4px #00000040;
}

.popupContentRBottom img {
    height: 57px;
    margin: 5px;
}

.Popup .closeBtn {
    position: absolute;
    margin-right: -610px;
    margin-top: -355px;
    z-index: 6;
}

.closeBtn {
    cursor: pointer;
}

@media screen and (max-width: 671px) and (min-width: 320px) {

    .popUpBottom,
    .Popup {
        display: none;
    }
}