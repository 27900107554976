.infopart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin-top: 35px;
    margin-bottom: 95px;
    color: #002D60;
}

.advertisementText {
    font-size: 24px;
    font-weight: 500;
}

.privilages {
    width: 80vw;
    max-width: 1120px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-between;

}

.privilage {
    max-width: 300px;
    margin-bottom: 20px;
}

.privilageTitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0px;
}

.privilageDescription {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}