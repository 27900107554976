@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  height: 100%;
}

.App {
  font-family: 'Poppins', sans-serif !important;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.popUpBottom {
  width: 100vw;
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  justify-content: center;
  color: white;
  background-color: #1997D6;
  font-size: 18px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.popUpBottomCenter img {
  height: 35px;
}
.popUpBottomCenter a{
  display: flex;
  align-items: center;
}

.closeBtn {
  height: 15px;
}

.popUpBottomCenter {
  width: calc(100% - 100px);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 671px) and (min-width: 320px) {

  .popUpBottom,
  .Popup {
    display: none;
  }
}