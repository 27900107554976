.InfoPartPhone {
    background-color: #F1FBFF;
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    box-shadow: 0px 4px 30px 0px #7F95AF40;
}

.InfoPartPhoneContent {
    display: flex;
    justify-content: space-around;
    width: 80vw;
    max-width: 1120px;
    padding: 80px 0px;
}

.InfoPartPhoneR {
    display: flex;
    align-self: center;
    flex-direction: column;
    height: 100%;
    /* max-height: 350px; */
    justify-content: space-around;
    margin-left: 30px;
}

.appTitle {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    color: #002D60;
}

.appDesc {
    max-width: 550px;
    font-family: Poppins;
    font-size: 21px;
    font-weight: 400;
    color: #002D60;
}

.appMidd {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
}

.appmob img {
    height: 45px;
}

@media screen and (max-width: 658px) and (min-width: 457px) {
    .appmob img:first-child {
        width: 140px;
    }

    .appmob img:last-child {
        height: 47px;
    }

}

@media screen and (max-width: 457px) and (min-width: 320px) {
    .InfoPartPhoneL {
        display: none;
    }
}