/* @import "~react-image-gallery/styles/scss/image-gallery.scss"; */
@import "~react-image-gallery/styles/css/image-gallery.css";

.AdvertPage {
    width: 100vw;
    /* height: 100vh; */
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
}

hr {
    height: 0.5px;
    background-color: #d9d9d9;
    border-radius: 2px;
    border: none;
}

.AdvertPageContent {
    width: 80%;
    height: 100%;
    margin-top: 40px;
    max-width: 1120px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.AdvertPageContentL {
    width: 57%;
    display: flex;
    flex-direction: column;
}
.openPhonePopUp{
    display: flex;
    position: fixed;
    width: 100vw;
    height: calc(100vh - 200px);
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.openPhonePopUp div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border: 2px solid #1997D6;
    box-shadow: 0px 3.178051471710205px 6.35610294342041px 0px #00000040;
    width: 200px;
    height: 70px;
    border-radius: 15px;
    gap: 7px;
}
.AdvertPageCont1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.AdvertPageContentR {
    width: 38%;
    margin-bottom: 20px;
}

.image-gallery-image {
    max-height: 300px !important;
}

.AdvertPageContentL2Desc {
    display: flex;
    flex-direction: row;
}

.AdvertPageContentL1 {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdvertPageContentL1 img {
    /* height: 100%; */
    width: 100%;
    border-radius: 10px;
    border: 1px solid #1997d6;
}

.AdvertPageContentL2Desc .starsA {
    --rating: 4;
    font-size: 20px;
    border-radius: 20px;
}

.AdvertPageContentL2Desc .starsA::before {
    content: "★ ★ ★ ★ ★";
    background: linear-gradient(90deg, #FFB800 0%, #FFB800 calc(var(--rating) * 20%), #7F95AF 0%, transparent 1000%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.AdvertPageContentL2Title {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    color: #1997d6;
    width: 270px;
}

.AdvertPageContentL2Desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 270px;
    margin-top: 8px;
}

.AdvertPageContentL2Desc hr {
    height: 19px;
    width: 0.1px;
    border-radius: 2px;
    background-color: #DBDBDB;
    border: none;
}

.AdvertPageContentL2Desc span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #002d60;
}

.AdvertPageContentL2Desc span:last-child {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
}


.AdvertPageContentL3 button {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    height: 45px;
    color: white;
    background-color: #1997d6;
    border: none;
    border-radius: 15px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.AdvertPageCont2 {
    margin: 10px 20px;

}

.AdvertPageCont2 span {
    margin-top: 15px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
}

.AdvertPageCont3 {
    max-width: 428px;
    margin-top: 30px;
    color: #002d60;
}

.AdvertPageCont3Enum {
    margin-top: 20px;
}

.AdvertPageCont3EnumItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.verticalHr {
    height: 15px;
    border-radius: 0px;
    width: 2px;
    margin-left: 20px;
    margin-right: -12px;
    background-color: #1997D6;
}

.AdvertPageCont3 span {
    margin-left: 20px;
}

.blueSpan {
    margin-left: 0px !important;
}

.AdvertPageCont3Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AdvertPageCont3Title span:first-child {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #002d60;
}

.AdvertPageCont3Title span:last-child {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: #002d60;
    cursor: pointer;
}

.AdvertPageCont3Title span:last-child:hover {
    color: #1997D6;
    text-decoration: underline;
}

.AdvertPageCont3EnumItem span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
}

.AdvertPageCont3EnumItem {
    margin: 5px 0px;
}

.blueSpan {
    color: #1997D6 !important;
    font-weight: 600 !important;
}


.AdvertPageCont4 {
    max-width: 428px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AdvertPageCont4TitleL {
    width: 200px;
}

.AdvertPageCont4Title {
    width: 388px;
    margin: 0 auto;
}

.AdvertPageCont4Title,
.AdvertPageCont4TitleL {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AdvertPageCont4Comments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}

.AdvertPageCont4Comment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 388px;
    margin: 0 auto;
}

.AdvertPageCont4CommentTop {
    display: flex;
}

.AdvertPageCont4CommentTopL {
    display: flex;
    width: 240px;
}

.AdvertPage4ContentComment {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
}

.AdvertPageCont4CommentTopR {
    display: flex;
    align-items: flex-end;
}

.userInfoPart {
    width: 210px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.showScore {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #FFB800;
    margin-left: 5PX;
}

.scoreCount,
.AdvertPageCont4TitleR {
    display: flex;
    align-items: center;
}

.scoreCount {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
}

.AdvertPageCont4TitleR {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #1997D6;
}

.userInfoPartName,
.userInfoPartDate {
    font-family: Poppins;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #7F95AF !important;
}

.AdvertPageCont4 hr {
    margin: 7px 0;
}

.selfComment {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
    margin-top: 3px;
}

.AdvertPageCont4CommentTopRImg {
    width: 105px;
    display: flex;
    justify-content: space-between;
}

.AdvertPageContentR {
    height: min-content;
    /* background-color: #7F95AF; */
    background-color: #1997D6;
    border-radius: 25px;
    padding: 5px;
}

.image-gallery-slides {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
    min-height: 250px;
    box-shadow: 0px 4px 4px 0px #00000040;

}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 25px;
}

.image-gallery-thumbnails {
    display: flex;
    flex-wrap: wrap;
    height: min-content;
    padding: 20px !important;
}

.image-gallery-thumbnail {
    display: flex;
    flex-wrap: wrap;
    width: 50px;
}

.image-gallery-thumbnails,
.image-gallery-thumbnails-container {
    display: flex;
    flex-wrap: wrap;
}

.image-gallery-thumbnails-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.image-gallery-thumbnail-image {
    border-radius: 5px;
}

.fullscreen {
    width: 80vw;
    max-width: 1120px;
}

.image-gallery-using-mouse::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.image-gallery::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.image-gallery-content.fullscreen {
    /* background: #7F95AF; */
    background: #1997D6;
    border-radius: 25px;
    margin: auto;
    margin-top: 10vh;
    padding: auto;
}

.image-gallery-content.fullscreen .image-gallery-image {
    max-height: 600px !important;
}
.image-gallery-slides, .image-gallery-thumbnail-inner {
    background-color: white;
}
@media screen and (max-width: 1125px) and (min-width: 832px) {
    .AdvertPageCont1 {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .AdvertPageContentL2 {
        margin-left: 17px;
    }
}


@media screen and (max-width: 832px) and (min-width: 320px) {
    .AdvertPageContent {
        flex-direction: column-reverse;
    }

    .AdvertPageContentR {
        margin-bottom: 20px;
    }

    .AdvertPageContentL,
    .AdvertPageContentR {
        width: 80vw;
    }

    .AdvertPageCont1 {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .AdvertPageContentL2 {
        margin-left: 7px;
    }
}


@media screen and (max-width: 480px) and (min-width: 320px) {
    .AdvertPageCont4Title {
        flex-direction: column;
    }

    .AdvertPageCont4 hr,
    .selfComment {
        width: 80vw;
    }

    .AdvertPageCont4CommentTop {
        flex-direction: column;
    }
}