#root {
    height: 100%;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {
    background-color: #1997d6 !important;
}

.swal2-title,
div:where(.swal2-container) div:where(.swal2-popup) {
    font-family: Poppins !important;
}

div:where(.swal2-icon).swal2-success {
    border-color: #1997d6 !important;
    color: #1997d6 !important;
}

.swal2-actions button {
    background-color: #1997d6 !important;

}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
    background-color: #FF5948 !important;
}

/* FF5948 */
div:where(.swal2-icon).swal2-error {
    border-color: #FF5948 !important;
}