.Advertisements {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 1120px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.advertisementText {
    width: 80vw;
    max-width: 1120px;
    color: #002D60;
    font-size: 24px;
    font-weight: 400;
    margin: 40px 10px;
}

.adImg {
    border-bottom: 1px solid #7F95AF80;
    height: 128px;
    width: 100%;
}

.adImg img {
    height: 100%;
    max-width: 90%;
    z-index: 0;
    /* border-radius: 20px 20px 0px 0px; */
}

.Advertisement {
    height: 282px;
    width: 250px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 3.178051471710205px 6.35610294342041px 0px #00000040;
    margin: 15px;
    cursor: pointer;
}

.firmDesc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 55px;

}

.bottomPart {
    padding: 5px 15px 0px 15px;
}

.firmTitle,
.firmDesc {
    color: #002D60;
}

.firmTitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
}

.firmDesc {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
}

.adImg {
    display: flex;
    justify-content: center;
}

.starsCommentPrice {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.starsCommentPriceL {
    width: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.price {
    font-size: 19px;
    font-weight: 600;
    color: #1997d6;
}

.stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFB800;
    font-size: 16;
    font-weight: 600;
}

.comments {
    color: #84878C;
    font-size: 11px;
    font-weight: 500;
}

@media screen and (max-width: 624px) and (min-width: 320px) {
    .Advertisements {
        padding-top: 70px;
    }

}