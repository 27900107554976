.footer {
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #1997D6;
    color: white;
    z-index: 0;
}

.footerComponent {
    width: 80%;
    max-width: 1120px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
}

.footerImg {
    margin-right: 8px;
}

.topPart {
    justify-content: space-between;
}

.columnPart {
    font-size: 15px;
}

.topPart,
.loactionPart,
.phonePart,
.emailPart {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.columnPart {
    display: flex;
    flex-direction: column;
}

.socialMediaPart1 {
    display: flex;
    cursor: pointer;
    justify-self: center;
    gap: 16px;
}

.downPart {
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    font-size: 12px;
    font-family: Poppins;
    justify-content: space-between;
    margin-bottom: 80px
}

.downPartR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;
    width: calc(100% - 280px);
}


@media screen and (max-width: 1070px) and (min-width: 320px) {
    .topPart {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
    }

}

@media screen and (max-width: 1105px) and (min-width: 320px) {
    .downPart {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .downPartR {
        width: 100%;
    }

}

@media screen and (max-width: 763px) and (min-width: 320px) {
    .downPart {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .downPartR {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

}

@media screen and (max-width: 479px) and (min-width: 320px) {
    .downPart {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .downPartR {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .topPart {
        flex-direction: column;
        align-items: flex-start;
    }
}