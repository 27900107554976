.PopupM {
    display: none;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    color: white;
    font-size: 20px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 4px 4px 0px #00000040;
}

.PopupM .closeBtn {
    position: absolute;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    z-index: 6;
}

.popupContentM {
    background-color: #1997d6;
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.popupContentLM {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 110px;
    background-color: white;
    box-shadow: 0px 0px 4px 0px #00000040;

}

.popupContentLM img {
    height: 100px;
    width: 100px;
}

.popupContentRM {
    margin-left: 15px;
}

.popupContentRBottomM {
    display: flex;
    flex-direction: row;
}

.popupContentRBottomM img {
    height: 37px;
}

@media screen and (max-width: 671px) and (min-width: 320px) {
    .PopupM {
        display: flex;
    }
}