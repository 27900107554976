.header {
    background-color: #FFFFFF;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: center;

}

.headerContent {
    width: 80vw;
    height: 100%;
    max-width: 1120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headerContentLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 500px;
}

.getapLogo {
    cursor: pointer;
}

.headerContentRight {
    width: 200px;
}
.headerContentRight img {
    margin-left: auto;
}

.loginSignupService {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.header button {
    background-color: #FFFFFF;
}

.headerContentRight button {
    font-weight: 500;
    font-size: 16px;
    width: 130px;
    height: 45px;
    border-radius: 15px;
    border: 2px solid #FF5948;
    color: #FF5948;
    font-family: Poppins;
}

.popUpSign button {
    color: #1997D6;
    display: flex;
    align-items: center;
    gap: 5px;
}

.searchHeader {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: min-content;
    gap: 5px;
    padding-left: 5px;
    height: 44px;
    border: 1px solid #1997D6;
    border-radius: 16px;
    width: 70%;
}

.searchHeader button {
    background-color: #1997D6;
    height: 100%;
    border: 1px solid #1997D6;
    padding: 0 20px;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 0px 14px 14px 0px;
    margin-left: auto;
    font-family: Poppins;
}

.searchHeader input {
    background-color: #FFFFFF;
    height: 100%;
    border: none;
    width: 100%;
    color: #7F95AF;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    font-family: Poppins;
}

.header ::placeholder {
    color: #7F95AF;
    opacity: 1;
}

.header :-ms-input-placeholder {
    color: #7F95AF;
}

.header ::-ms-input-placeholder {
    color: #7F95AF;
}

.popUpSign {
    position: absolute;
    margin-top: 2px;
    margin-left: -9px;
    background-color: white;
    width: 115px;
    display: flex;
    flex-direction: column;
    padding: 7px 10px;
    border: 1px solid #1997d6;
    border-radius: 13px 0px 13px 13px;
    z-index: 10;
    justify-content: center;
    align-items: center;
}
.popUpSign span{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
}
.popUpSign hr {
    border: none;
    height: 0.1px;
    background-color: #1997D6;
}

.serviceCon {
    display: none;
}

.loginSignupService img {
    cursor: pointer;
}

.popUpSign button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 80px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.popUpSign {
    animation: slideIn 1s ease;
}
@media screen and (max-width: 865px) and (min-width: 800px) {
    .searchHeader {
        width: auto;
    }

    .headerContentLeft {
        width: 450px;
        justify-content: space-between;
        margin-right: 10px;
    }
}

@media screen and (max-width: 800px) and (min-width: 646px) {
    .getapLogo img {
        content: url("../../img/getapLogoMobile.svg");
    }

    .searchHeader {
        width: auto;
    }

    .searchHeader input {
        margin-right: 15px;
    }

    .headerContentLeft {
        width: 450px;
        justify-content: space-between;
        margin-right: 10px;
    }

    .headerContentRight {
        width: 250px;
    }
}

@media screen and (max-width: 645px) and (min-width: 320px) {
    .serviceCon {
        display: block;
    }
}
@media screen and (max-width: 645px) and (min-width: 414px) {
    .headerContentRight button {
        font-weight: 400;
        font-size: 14px;
        width: 100px;
        height: 45px;
        border-radius: 15px;
        border: 2px solid #FF5948;
        color: #FF5948;
        font-family: Poppins;
    }
}

@media screen and (max-width: 414px) and (min-width: 320px) {
    .headerContentRight button {
        font-weight: 400;
        font-size: 14px;
        width: 60px;
        height: 45px;
        border-radius: 15px;
        border: 2px solid #FF5948;
        color: #FF5948;
        font-family: Poppins;
    }
}


@media screen and (min-width: 1190px) {
    .searchHeader {
        width: 80%;
    }

    .headerContentLeft {
        width: 750px;
    }
}


@media screen and (max-width: 645px) and (min-width: 470px) {
    .getapLogo img {
        content: url("../../img/getapLogoMobile.svg");
    }


    .popUpSign {
        right: 60px;
    }

    .loginSignupService {
        justify-content: flex-end;
    }

    .searchHeader {
        width: auto;
        margin-left: 7px;
        margin-right: 7px;
    }
}

@media screen and (max-width: 469px) and (min-width: 410px) {
    .popUpSign {
        margin-top: 17px;
    }
}

@media screen and (max-width: 469px) and (min-width: 410px) {
    .header {
        height: 80px;
    }

    .getapLogo img {
        content: url("../../img/getapLogoMobile.svg");
    }
    .headerContent {
        width: 95vw;
    }


    .loginSignupService {
        justify-content: flex-end;
    }

    .searchHeader {
        width: auto;
        margin-left: 17px;
        margin-right: 7px;
    }

    .searchHeader input {
        width: 130px;
    }

    .popUpSign {
        right: 40px;
    }
}

@media screen and (max-width: 410px) and (min-width: 320px) {
    .header {
        height: 80px;
    }

    .getapLogo img {
        content: url("../../img/getapLogoMobile.svg");
    }

    .loginSignupService {
        justify-content: flex-end;
    }

    .searchHeader {
        width: auto;
        margin-left: 7px;
        margin-right: 7px;
    }

    .searchHeader input {
        width: 100px;
    }

    .headerContent {
        width: 95vw;
    }

    .popUpSign {
        right: 10px;
    }
}

@media screen and (max-width: 350px) and (min-width: 320px) {
    .popUpNotifications{
        margin-right: 0px !important;
    }
}
.popUpSign {
    --b: 2px;
    --c: #1997d6;
    --r: 9px;
    display: inline-block;
    border: 2px solid #1997D6;
    background: #fff;
    border-radius: 13px 0 13px 13px;
    clip-path: inset(-100px 0);
}

.popUpSign:before {
    content: "";
    position: absolute;
    left: var(--r);
    right: calc(-1*var(--b));
    bottom: 100%;
    height: 7px;
    border-right: var(--b) solid var(--c);
    border-bottom: var(--b) solid var(--c);
    border-bottom-right-radius: 10px;
    box-shadow: var(--r) var(--b) 0 #fff;
}

.popUpSign:after {
    content: "";
    position: absolute;
    right: calc(-1*var(--b));
    bottom: calc(100% - var(--b));
    width: var(--b);
    height: var(--r);
    background: var(--c);
}
.Notifications{
    display: flex;
    flex-direction: column;
    max-width: 388px;
}
.Notification{
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    margin-top: 10px;
    box-shadow: 0px 4.554382801055908px 11.385957717895508px 4.554382801055908px #0000001A;
    padding: 10px;
}

.NotificationLayer1, .NotificationLayer4{
    display: flex;
    justify-content: flex-end;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #7F95AF;
}
.NotificationLayer2, .NotificationLayer3{
    display: flex;
    justify-content: flex-start;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #002d60;
}

.popUpNotifications{
    animation: slideIn 1s ease;
    position: absolute;
    margin-top: 17px;
    margin-right: 15px;
    right: 0;
    display: flex;
    z-index: 10;
}

.showProvideService{
    display: flex;
    position: fixed;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
.showProvideServiceContent{
    width: 300px;
    height: auto;
    border-radius: 16px;
    padding: 15px;
    border: 2px solid #1997d6;
    background-color: white;
}

.showProvideServiceContent h2 {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    color: #1997D6;
}

.showProvideServiceContent label {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    color: #7F95AF;
    margin-bottom: 3px;
}

.showProvideServiceContent input,
.showProvideServiceContent textarea {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: black;
    padding: 2px 9px;
    outline-style: 1px solid #1997D6;
    outline-color: #1997D6;
    border: 2px solid #7F95AF;
    border-radius: 11px;
    resize: none;
    height: 28px;
}

.showProvideServiceContent button:first-child {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    width: 115px;
    height: 40px;
    border-radius: 13.125px;
    border: 1.5px;
    background: linear-gradient(0deg, #1997D6, #1997D6);
    color: white;
}

.showProvideServiceContent button:last-child {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    width: 60px;
    height: 40px;
    border: none;
    text-decoration: underline;
    background-color: transparent;
    color: #FF5948;
}
.form-input1{
    display: flex;
    flex-direction: column;
    width: 270px;
    gap: 5px;
}
.form1{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}
.needToFill{
    margin-top: -5px !important;
    font-size: 10px !important;
    color: red !important;
    display: block !important;
}
.form-btn-container{
    display: flex;
    justify-content: space-between;
    width: 270px;
}

.form-btn-container .btn-cancel{
    width: 100px !important;
}

.form-input1Phone1 select {
    background-color: rgba(25, 151, 214, 1);
    border-radius: 12px 0px 0px 12px;
    height: 36px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    width: 55px;
    border: 1px solid rgba(25, 151, 214, 1);
    outline: none;
}
.form-input1Phone1 input {
    border-radius: 0px 11px 11px 0px;
    width: 193px;
}