.searchpart {
    background-color: #F1FBFF;
    box-shadow: 0 3px 6px 3px rgba(127, 149, 175, 0.25);
    width: 100vw;
    color: rgba(127, 149, 175, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.serviceRequestPart {
    width: 100%;
    max-width: 1120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.serviceRequestPart span {
    font-size: 21px;
    font-weight: 400;
    font-family: Poppins;
    width: 80vw;
    max-width: 1120px;
    margin-bottom: 10px;
    margin-top: 60px;
}

.serviceRequest {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 80vw;
    height: 110px;
    max-width: 1120px;
    box-shadow: 0 3px 20px 1.4px rgba(127, 149, 175, 0.25);
    border-radius: 25px;
    font-size: 17px !important;
    padding-right: 10px;
}

.serviceRequest input,
.serviceRequestPart select:last-child {
    border: 2px solid rgba(25, 151, 214, 1);
    height: 38px;
    width: 100%;
    border-radius: 12px;
    padding-left: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    outline: none;
}

.serviceType,
.serviceData,
.serviceNumber {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 22%;
    min-width: 190px;
    margin: 0 20px;
}

.serviceType label,
.serviceData label,
.serviceNumber label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;

}

#datePickerId2,
#inputPersonNum2,
#inputFrom2,
#inputTo2 {
    display: flex;
    align-items: center;
}

.react-datepicker__header,
.react-datepicker__day--selected {
    background-color: #1997d6 !important;
    border-bottom: none !important;
    color: white !important;
}

.react-datepicker__triangle::after {
    border-bottom-color: #1997d6 !important;

}

.react-datepicker__day-name,
.react-datepicker__current-month {
    color: white !important;
}

.serviceRequest ::placeholder {
    color: rgba(0, 45, 96, 1);
    opacity: 1;
}

.serviceRequest :-ms-input-placeholder {
    color: rgba(0, 45, 96, 1);
}

.serviceRequest ::-ms-input-placeholder {
    color: rgba(0, 45, 96, 1);
}

.inputOfphoneNumber {
    display: flex;
    flex-direction: row;
}

.serviceNumber input {
    width: calc(100% - 55px);
    border-radius: 0px 12px 12px 0px;
}

.serviceNumber select {
    background-color: rgba(25, 151, 214, 1);
    border-radius: 12px 0px 0px 12px;
    height: 41px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    width: 55px;
    outline: none;
    border: 1px solid rgba(25, 151, 214, 1);
    ;
}

.serviceButton button {
    height: 50px;
    width: 146px;
    border-radius: 13px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: white;
    background-color: #1997d7;
    border: 1px solid rgba(25, 151, 214, 1);
}


.filters {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100vw;
    margin-top: 80px;
    height: 99px;
    background-color: white;
    box-shadow: 0px 2px 4px 4px rgba(25, 151, 214, 0.25);
}

.makeSmallImg {
    width: 70px;
}

.filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-family: Poppins;
    gap: 5px;
    width: 80px;
    height: 99px;
    cursor: pointer;
}

.filter span {
    display: inline-block;
    width: max-content;
    text-align: center;
    align-self: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #002D60;
    ;

}

.filter img {
    width: 35px;
    height: 35px;
}

.rec.rec-arrow {
    position: relative;
    z-index: 0;
    background-color: white;
    transition: all 0.3s ease 0s;
    font-size: 1.6em;
    color: #1997D6;
    box-shadow: 0px 4px 4px 0px #1997D640;
    border: none;
    border-radius: 0px;
    padding: 0px;
    width: 155px;
    height: 99px;
    align-self: center;
    cursor: pointer;
    outline: none !important;
}

.rec.rec-arrow:hover:enabled,
.rec.rec-arrow:focus:enabled {
    color: #1997D6;
    background-color: white;
    box-shadow: 4px 0px 4px 0px #1997D640;
    border: none;
    border-radius: 0px;
    padding: 0px;
    width: 155px;
    height: 99px;
    align-self: center;
    cursor: pointer;
    outline: none !important;
}

.rec-carousel-item:focus {
    outline: none;
    background-color: black;
    color: #1997D6;
    border: none;
    border-radius: 0px;
    padding: 0px;
    width: 155px;
    height: 99px;
    align-self: center;
    cursor: pointer;
    outline: none !important;
    box-shadow: 4px 0px 4px 0px #1997D640;
}

.rec.rec-dot {
    background-color: white;
    color: #1997D6;
    box-shadow: 4px 0px 4px 0px #1997D640;
    border: none;
    border-radius: 0px;
    padding: 0px;
    width: 155px;
    height: 99px;
    align-self: center;
    cursor: pointer;
    outline: none !important;
}

.rec.rec-dot_active {
    background-color: #1997D6;
    color: #1997D6;
    box-shadow: 4px 0px 4px 0px #1997D640;
    border: none;
    border-radius: 0px;
    padding: 0px;
    width: 155px;
    height: 99px;
    align-self: center;
    cursor: pointer;
    outline: none !important;
}

.rec.rec-dot_active:hover,
.rec.rec-dot_active:focus {
    background-color: #1997D6;
    color: #1997D6;
    box-shadow: 4px 0px 4px 0px #1997D640;
    border: none;
    border-radius: 0px;
    padding: 0px;
    width: 155px;
    height: 99px;
    align-self: center;
    cursor: pointer;
    outline: none !important;
}

/* .rec-slider-container{
    max-width: 1120px;
} */



@media screen and (max-width: 1032px) and (min-width: 320px) {
    .serviceRequest {
        flex-wrap: wrap !important;
        height: auto;
        padding-bottom: 15px;
    }

    .serviceButton {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        width: 250px;
    }

    .serviceNumber input {
        width: 100% !important;
    }

    .serviceType,
    .serviceData,
    .serviceNumber {
        margin: 10px 30px;
    }

    .serviceType input,
    .serviceData input,
    .serviceNumber input {
        width: calc(100% - 14px);
    }

}

@media screen and (max-width: 624px) and (min-width: 320px) {

    .rec.rec-arrow {
        display: none !important;
    }
    .serviceType,
    .serviceData,
    .serviceNumber {
        width: 300px;
    }

    .serviceType input,
    .serviceData input {
        width: calc(100% - 15px);
    }

    .searchpart {
        padding-top: 50px;
    }

}


.searchServiceİnput option {
    color: #1997D6;
    overflow-y: scroll;
    overflow-x: hidden;
}

.searchServiceİnput{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.searchServiceİnput {
    background-image:
      linear-gradient(45deg, transparent 50%, #1997d6 50%),
      linear-gradient(135deg, #1997d6 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position:
      calc(100% - 14.5px) calc(1em + 2px),
      calc(100% - 10px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
}